import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 100">
    <title>Binary Logo</title>
    <text
      x="10"
      y="70"
      fill="currentColor"
      fontSize="70"
      fontFamily="monospace"
    >
     01001000 01101001
    </text>
  </svg>
);

export default IconLoader;
